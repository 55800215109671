import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import Loading from './Loading';
import Table from './Table';
import '../css/main.css';


const Bets = () => {
    const { result: bets, isLoading: betsLoading, error: betsError } = useFetch(`/bets`,[]);
    const [PlayerBetsTableContent, setPlayerBetsTableContent] = useState(null);
    const [TeamBetsTableContent, setTeamBetsTableContent] = useState(null);
    const [view, setView] = useState("players");
    const [dateFilter, setDateFilter] = useState(null);
    // const [valueBets, setValueBets] = useState(false);

    const PlayerBetsTableHeader = [
      { content: "Game Date", size: "md", isLink: false },
      { content: "Time", size: "md", isLink: false },
      { content: "Bet Type", size: "md", isLink: false },
      { content: "Home", size: "md", isLink: true, link: 'game' },
      { content: "Away", size: "md", isLink: true, link: 'game' },
      { content: "League", size: "md", isLink: true, link: 'league' },
      { content: "Player", size: "md", isLink: true, link: 'player' },
      { content: "Team", size: "md", isLink: true, link: 'team' },
      { content: "Value Odd", size: "sm", isLink: false },
      { content: "W Odd", size: "sm", isLink: false },
      { content: "Value", size: "sm", isLink: false },
      { content: "Result", size: "sm", isLink: false },
    ];

    const TeamBetsTableHeader = [
        { content: "Game Date", size: "md", isLink: false },
        { content: "Time", size: "md", isLink: false },
        { content: "Bet Type", size: "md", isLink: false },
        { content: "Home", size: "md", isLink: true, link: 'game' },
        { content: "Away", size: "md", isLink: true, link: 'game' },
        { content: "League", size: "md", isLink: true, link: 'league' },
        { content: "Value Odd", size: "sm", isLink: false },
        { content: "Result", size: "sm", isLink: false },
      ];
    
    useEffect(() => {
      if (bets !== null) {
     setPlayerBetsTableContent(
              bets.player_bets.filter((bet) => {
                if (dateFilter === "today") {
                    return new Date(bet.game.date_time).toISOString().split('T')[0] === new Date().toISOString().split('T')[0];
                } else if (dateFilter === "tomorrow") {
                    return new Date(bet.game.date_time).toISOString().split('T')[0] === new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];
                } else if (dateFilter === "yesterday") {
                    return new Date(bet.game.date_time).toISOString().split('T')[0] === new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
                }
                else {
                    return true;
                }
              }).map((bet) => [
                    bet.game ? new Date(bet.game.date_time).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }) : "",
                    bet.game ? new Date(bet.game.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : "",
                    bet.bet_type,
                    bet.game ? [bet.game.home_team.name, bet.game.home_team.id] : ["", ""],
                    bet.game ? [bet.game.away_team.name, bet.game.away_team.id] : ["", ""],
                    bet.game ? [bet.game.league.name, bet.game.league.id] : ["", ""],
                    bet.player ? [bet.player.name, bet.player.id] : ["", ""],
                    bet.player.current_team ? [bet.player.current_team.name, bet.player.current_team.id] : ["", ""],
                    bet.value_odd,
                    bet.winamax_odd,
                    bet.is_value ? <i style={{color: "green"}} className="fa-solid fa-check"></i> : <i style={{color: "red"}} className="fa-solid fa-xmark"></i>,
                    bet.bet_result,
              ])
          );
          setTeamBetsTableContent(
            bets.team_bets.filter((bet) => {
                if (dateFilter === "today") {
                    return new Date(bet.game.date_time).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]  ;
                } else if (dateFilter === "tomorrow") {
                    return new Date(bet.game.date_time).toISOString().split('T')[0] === new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().split('T')[0];
                } else if (dateFilter === "yesterday") {
                    return new Date(bet.game.date_time).toISOString().split('T')[0] === new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0];
                }
                else {
                    return true;
                }
              }).map((bet) => [
                  bet.game ? new Date(bet.game.date_time).toLocaleDateString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }) : "",
                  bet.game ? new Date(bet.game.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : "",
                  bet.bet_type,
                  bet.game ? [bet.game.home_team.name, bet.game.home_team.id] : ["", ""],
                  bet.game ? [bet.game.away_team.name, bet.game.away_team.id] : ["", ""],
                  bet.game ? [bet.game.league.name, bet.game.league.id] : ["", ""],
                  bet.value_odd,
                  bet.bet_result,
            ])
        );
      }
      
  }, [bets, dateFilter]);
  

  return (
    <>
    <div class="container">
    <div className="buttons">
    <div className={`button ${dateFilter === "yesterday" ? 'active' : ''}`} onClick={() => { dateFilter === "yesterday" ? setDateFilter(null) : setDateFilter("yesterday"); }}>YESTERDAY</div>
        <div className={`button ${dateFilter === "today" ? 'active' : ''}`} onClick={() => { dateFilter === "today" ? setDateFilter(null) : setDateFilter("today"); }}>TODAY</div>
        <div className={`button ${dateFilter === "tomorrow" ? 'active' : ''}`} onClick={() => { dateFilter === "tomorrow" ? setDateFilter(null) : setDateFilter("tomorrow"); }}>TOMORROW</div>
    </div>
    <div className="buttons">
        <div className={`button ${view === "players" ? 'active' : ''}`} onClick={() => { view !== "players" && setView("players");}}>PLAYERS</div>
        <div className={`button ${view === "teams" ? 'active' : ''}`} onClick={() => { view !== "teams" && setView("teams"); }}>TEAMS</div>
    </div>
    {betsLoading && <Loading />}
    {view === "players" && !betsLoading && !betsError && bets.player_bets.length > 0 && <Table tableContent={PlayerBetsTableContent} tableHeader={PlayerBetsTableHeader} />}
    {view === "teams" && !betsLoading && !betsError && bets.team_bets.length > 0 && <Table tableContent={TeamBetsTableContent} tableHeader={TeamBetsTableHeader} />}
{betsError && <>{betsError} - Please contact Florent</>}
  </div>

</>
  )
}

export default Bets;