import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../css/main.css';
import Loading from './Loading';
import Table from './Table';


const Team = () => {
    const { id } = useParams();
    const [tab, setTab] = useState("Fixtures")
    const [homeAway, setHomeAway] = useState(null)
    const [gamesTableContent, setGamesTableContent] = useState(null);
    const [playersTableContent, setPlayersTableContent] = useState(null);

    const { result: team, isLoading: teamLoading, error: teamError} = useFetch(`/team/${id}`,[]);

      const gamesTableHeader = [
        { content: "Competition", size: "md", isLink: true, link: 'league', sort: 'normal' },
        { content: "Date", size: "md", isLink: false, sort: 'normal'  },
        { content: "Home", size: "md", isLink: true, link: 'team', sort: 'normal' },
        { content: "Away", size: "md", isLink: true, link: 'team', sort: 'normal' },
        { content: "Lineups", size: "sm", isLink: false, sort: 'normal' },
        { content: "Status", size: "md", isLink: false, sort: 'normal' },
        { content: "Score", size: "sm", isLink: true, link: 'game', sort: 'normal' },
        { content: "Xwin", size: "sm", isLink: false, sort: 'abs' },
        { content: "Xgoals", size: "sm", isLink: false, sort: 'normal' },
        { content: "XBTTS", size: "sm", isLink: false, sort: 'normal' },
        { content: "UNXF", size: "sm", isLink: false, sort: 'normal' }
      ];

      const playersTableHeader = [
        { content: "Position", size: "md", isLink: false, sort: 'normal' },
        { content: "Name", size: "md", isLink: true, link: 'player', sort: 'normal' },
        { content: "Starter", size: "md", isLink: false, sort: 'normal'  },
        { content: "Substitute", size: "md", isLink: false, sort: 'normal' },
        { content: "Out", size: "md", isLink: false, sort: 'normal' },
        { content: "Ratio", size: "md", isLink: false, sort: 'normal' },
        { content: "Goals", size: "md", isLink: false, sort: 'normal' },
        { content: "Assists", size: "md", isLink: false, sort: 'normal' },
        { content: "G + A", size: "md", isLink: false, sort: 'normal' }
      ];

    useEffect(() => {
        if (team !== null) {
          setPlayersTableContent(
            team.current_players.map((player) => 
              [player.position, 
              [player.name,player.id],
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "Starter").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0),
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "Substitute").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0),
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "Out_of_squad").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0),
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "starting_ratio").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0),
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "Goals").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0),
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "Assists").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0),
              player.player_stats.filter(player_stat => player_stat.team === team.id).filter(player_stat => player_stat.stat === "Goals + Assists").map(player_stat => Number(player_stat.value)).reduce((acc, value) => acc + value, 0)]));
        }
      }, [team]);

    useEffect(() => {
        if (team !== null) {
          setGamesTableContent(
            team.games.map((game) => 
                [[game.league.name,game.league.api_id],
                new Date(game.date_time).toLocaleDateString(),
                [game.home_team.name,game.home_team.id],
                [game.away_team.name,game.away_team.id],
                game.has_lineups,
                game.status,
                [game.status === "Match Finished" ? `${game.goals_home_et + game.goals_home_ft} - ${game.goals_away_et + game.goals_away_ft}` : ' --- ',game.id],
                game.win_prediction,
                game.goals_nb_prediction_poisson,
                game.bts_prediction,
                game.unexpected_factor
              ]
          ));
        }
      }, [team]);

  return (
   <>
            {teamLoading ? (
                <Loading />
            ) : team && (
                <>
                <div className="content">
                    <div className="header">
                        <div className="info">
                            <center>
                            <br />
                        <b>Country</b><br />{team.league && team.league.country.name}
                        <br />
                        <br />
                        <br />
                        <b>League</b><br /><Link to={team.league && `/league/${team.league.api_id}`}>{team.league && team.league.name}</Link>
                        </center>
                        </div>
                    </div>
                    <div className="header">
                        <img className="header-logo" src={team.logo_url} alt={team.name} />
                        <center><b>{team.name}</b></center>
                    </div>
                    <div className="header">
                        <div className="info">
                            <center>
                        <b>Coach</b><br /><Link to={team.current_coach && `/coach/${team.current_coach.id}`}>{team.current_coach && team.current_coach.name}</Link>
                        <div>
                          <img className="header-logo" src={team.current_coach.photo_url} alt={team.current_coach.name} />
                         </div> 
                        </center>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                <div className="tabs-container">
                    <div onClick={()=>setTab("Fixtures")} className={`tab ${tab === "Fixtures" ? "active" : ""} `}>Fixtures</div>
                    <div onClick={()=>setTab("Players")} className={`tab ${tab === "Players" ? "active" : ""} `}>Players</div>
                    <div onClick={()=>setTab("Stats")} className={`tab ${tab === "Stats" ? "active" : ""} `}>Stats</div>
                </div>
                    {tab === "Players" &&
                    <>
                    {team && <Table tableHeader={playersTableHeader} tableContent={playersTableContent} />}
                    </>
                    }
                    {tab==="Fixtures" && 
                    <>
                    {team && (
                      <div className="content">
                        <div className="container">
                        <div className="buttons">
                          <div className={`button ${homeAway === "HOME" && 'active'}`} onClick={(e) => {homeAway === "HOME" ? setHomeAway(null) : setHomeAway("HOME")}}>HOME</div>
                          <div className={`button ${homeAway === "AWAY" && 'active'}`}  onClick={(e) => {homeAway === "AWAY" ? setHomeAway(null) : setHomeAway("AWAY")}}>AWAY</div>
                        </div>
                        {team && !teamLoading && !teamError && <Table tableHeader={gamesTableHeader} tableContent={gamesTableContent} />}
                        {teamLoading && <Loading />}
                        {teamError && <>{teamError} - Please contact Florent</>}
                        </div>
                      </div>
                    )}
                  </>
                    }
                    </div>
                </>
                )
            }
    </>
  )
}

export default Team;