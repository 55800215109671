import React from 'react';
import { useContext } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import '../css/main.css';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import SidebarContext from '../context/SidebarContext';
import SearchResultsContext from '../context/SearchTermContext';
import Leagues from './Leagues';
import League from './League';
import Team from './Team';
import Player from './Player';
import Game from './Game';
import Coach from './Coach';
import Teams from './Teams';
import Logo from '../static/img/datarium_logo_simplified.png';
import Bets from './Bets';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Players from './Players';

const Main = () => {

    const { collapsed } = useContext(SidebarContext);
    const { setSearchResults } = useContext(SearchResultsContext);
    const clearSearchResults = () => {
        setSearchResults([]);
      };

    // const notify = (text, status="success") => {
    //     if (status === 'success') {
    //     toast.success(text, {
    //       position: toast.POSITION.BOTTOM_RIGHT,
    //       className: "custom-toast",
    //     });}
    //     else {
    //         toast.error(text, {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             className: "custom-toast",
    //           });
    //     }

    //   };

    return (
        <div>
            <div className={`rectangle ${collapsed ? 'collapsed' : ''}`}>
            {((window.location.hostname === 'localhost') &&
                    (<Link to ="/"><div className="logo"><img src={Logo} alt="Development Logo" width="80px" /></div></Link>))
            ||
                (<Link to ="/"><div className="logo"><img src={Logo} alt="Datarium Logo" width="80px" /></div></Link>)
            }
            </div>
        <Topbar clearSearchResults={clearSearchResults} />
        <Sidebar />
            <div className={`main ${collapsed ? 'collapsed' : ''}`}>
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/leagues" element={<Leagues />} />
                    <Route exact path="/league/:id" element={<League />} />
                    <Route exact path="/team/:id" element={<Team />} />
                    <Route exact path="/player/:id" element={<Player />} />
                    <Route exact path="/game/:id" element={<Game />} />
                    <Route exact path="/coach/:id" element={<Coach />} />
                    <Route exact path="/players" element={<Players />} />
                    <Route exact path="/teams" element={<Teams />} />
                    <Route exact path="/bets" element={<Bets />} />
                </Routes>
            </div>
            {/* <ToastContainer   autoClose={2000} hideProgressBar /> */}
        </div>
    );
};

export default Main;