import { useState, useEffect } from 'react';
import useFetch from '../api/useFetch';
import Table from './Table';
import Loading from './Loading';

const Dashboard = () => {
  function getFormattedDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${month}-${day}-${year}`;
  }

  function pickedDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  const today = new Date();
  const [date, setDate] = useState(today);
  const { result: games, isLoading: gamesLoading, error: gamesError} = useFetch(`/games_by_date/${getFormattedDate(date)}`, [date]);
  
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [gamesTableContent, setGamesTableContent] = useState([]);


  const handlePrevDate = () => {
    const prevDate = new Date(date);
    prevDate.setDate(prevDate.getDate() - 1);
    setDate(prevDate);
    setSelectedLeague(null);
  };

  const handleNextDate = () => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    setDate(nextDate);
    setSelectedLeague(null);
  };

  const gamesTableHeader = [
    { content: "Competition", size: "md", isLink: true, link: 'league', sort: 'normal' },
    { content: "Time", size: "md", isLink: false, sort: 'normal'  },
    { content: "Home", size: "md", isLink: true, link: 'team', sort: 'normal' },
    { content: "Away", size: "md", isLink: true, link: 'team', sort: 'normal' },
    { content: "Winamax ID", size: "md", isLink: false, sort: 'normal' },
    { content: "Status", size: "md", isLink: false, sort: 'normal' },
    { content: "Score", size: "sm", isLink: true, link: 'game', sort: 'normal' },
    { content: "Xwin", size: "sm", isLink: false, sort: 'abs' },

    { content: "Xgoals", size: "sm", isLink: false, sort: 'normal' },
    { content: "XBTTS", size: "sm", isLink: false, sort: 'normal' },
    { content: "X!BTTS", size: "sm", isLink: false, sort: 'normal' },
    { content: "UNXF", size: "sm", isLink: false, sort: 'normal' }
  ];

  useEffect(() => {
    if (games !== null) {
      setGamesTableContent(
        games.items.filter(game => selectedLeague === null || game.league.id === selectedLeague.id).map((game) => 
            [[game.league.name,game.league.api_id],
            new Date(game.date_time).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
            [game.home_team.name,game.home_team.id],
            [game.away_team.name,game.away_team.id],
            game.winamax_id,
            game.status,
            [game.status === "Match Finished" ? `${game.goals_home_et + game.goals_home_ft} - ${game.goals_away_et + game.goals_away_ft}` : ' --- ',game.id],
            game.win_prediction,
            game.goals_nb_prediction_poisson,
            game.bts_prediction ? (1 / game.bts_prediction).toFixed(2) : '',
            game.bts_prediction ? (1 / (1 - game.bts_prediction)).toFixed(2) : '',
            game.unexpected_factor
          ]
      ));
    }
  }, [games, selectedLeague]);

  return (
    <>
    {gamesLoading && <Loading />}
    {!gamesLoading && gamesError && <>{gamesError} - Please contact Florent</>}
      {!gamesLoading && games && (
        <div className="content">
          <div className="container">
            <div className="league-choice">
            {games && games.leagues.map((league) => {return (
              <img src={league.logo_url} alt={league.name} onClick={() => {selectedLeague === league ? setSelectedLeague(null) : setSelectedLeague(league)}} className={selectedLeague === league && 'active'} />
            )})}
            </div>
            <div className="table">
            <div className="button" onClick={(e) => {setDate(new Date()); setSelectedLeague(null)}}>TODAY</div>
              <div className="pagin-top">
                <i className="fas fa-arrow-circle-left" onClick={handlePrevDate}></i>
                <div className="number"><input type="date" value={pickedDate(date)} onChange={(e) => setDate(new Date(e.target.value))}/></div>
                <i className="fas fa-arrow-circle-right" onClick={handleNextDate}></i>
              </div>
              <>
                    {games && !gamesLoading && !gamesError && <Table tableHeader={gamesTableHeader} tableContent={gamesTableContent} />}
                    {gamesError && <>{gamesError} - Please contact Florent</>}
              </>

              </div>
            </div>
          </div>
      )}
    </>
  );
};

export default Dashboard;
