import React, { useState, useEffect } from "react";
import TableLine from "./TableLine";

const Table = ({ tableHeader, tableContent }) => {
  const [sortedContent, setSortedContent] = useState(tableContent);
  const [sortConfig, setSortConfig] = useState({ index: null, direction: 'ascending' });

  useEffect(() => {
    setSortedContent(tableContent);
  }, [tableContent]);

  const handleSort = (index) => {
    let direction = 'ascending';
  
    // Toggle direction if the same column is clicked
    if (sortConfig.index === index) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
  
    const sortedData = [...sortedContent].sort((a, b) => {
      // Convert values to numbers if possible, otherwise treat them as strings
      if (a[index] === '∞' && direction === 'ascending') {
        return 1;
      }
      if (b[index] === '∞' && direction === 'ascending') {
        return -1;
      }
      if (a[index] === '∞' && direction === 'descending') {
        return -1;
      }
      if (b[index] === '∞' && direction === 'descending') {
        return 1;
      }
      const valueA = parseFloat(a[index]);
      const valueB = parseFloat(b[index]);
  
      // If both values are valid numbers, compare them numerically
      if (!isNaN(valueA) && !isNaN(valueB)) {
        return direction === 'ascending' ? valueA - valueB : valueB - valueA;
      }
  
      // If any of the values cannot be converted to numbers, compare them as strings
      if (a[index] < b[index]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[index] > b[index]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    setSortedContent(sortedData);
    setSortConfig({ index, direction });
  };

  const handleSortAbs = (index) => {
    let direction = 'ascending';
    
    // Toggle direction if the same column is clicked
    if (sortConfig.index === index) {
      direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
  
    const sortedData = [...sortedContent].sort((a, b) => {
      // Compare by absolute value
      if (a[index] === '∞' && direction === 'ascending') {
        return 1;
      }
      if (b[index] === '∞' && direction === 'ascending') {
        return -1;
      }
      if (a[index] === '∞' && direction === 'descending') {
        return -1;
      }
      if (b[index] === '∞' && direction === 'descending') {
        return 1;
      }
      const absA = Math.abs(a[index]);
      const absB = Math.abs(b[index]);
  
      if (absA < absB) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (absA > absB) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  
    // Update state with sorted data and current sort configuration
    setSortedContent(sortedData);
    setSortConfig({ index, direction });
  };
  

  return (
    <div className="generic-table">
      <div className="table-header">
        {tableHeader.map((element, idx) => (
          <div
            key={element.content}
            className={`${element.size} 'sortable'}`}
            onClick={() => element.sort === 'normal' ? handleSort(idx) : handleSortAbs(idx)}
            style={{ cursor: 'pointer' }}
          >
            {element.content} 
            {sortConfig.index === idx ? (
              sortConfig.direction === 'ascending' ? ' ↑' : ' ↓'
            ) : ''}
          </div>
        ))}
      </div>
      <div className="table-body">
        {sortedContent && sortedContent.map((item, itemIdx) => (
          <div key={itemIdx} className="table-row">
            <TableLine header={tableHeader} content={item} id={itemIdx} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;