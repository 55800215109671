import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import Loading from './Loading';
import Table from './Table';
import '../css/main.css';


const Players = () => {
    const [view, setView] = useState("players");
    const { result: players, isLoading: playersLoading, error: playersError  } = useFetch('/top_players',[]);
    const [playersTableContent, setplayersTableContent] = useState(null);
    const [duosTableContent, setDuosTableContent] = useState(null);
    const [triosTableContent, setTriosTableContent] = useState(null);
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [homeAway, setHomeAway] = useState(null);
    const [propType, setPropType] = useState(null);
    const playersTableHeader = [
      { content: "player", size: "md", isLink: true, link: 'player' },
      { content: "position", size: "sm", isLink: false},
      { content: "WID", size: "sm", isLink: false },
      { content: "Team", size: "md", isLink: true, link: 'team' },
      { content: "League", size: "md", isLink: true, link: 'league' },
      { content: "Top Striker", size: "sm", isLink: false },
      { content: "Top Assist", size: "sm", isLink: false },
      { content: "Games", size: "sm", isLink: false },
      { content: "Goals", size: "sm", isLink: false },
      { content: "Odd", size: "sm", isLink: false },
      { content: "Assists", size: "sm", isLink: false },
      { content: "Odd", size: "sm", isLink: false },
      { content: "Decisive", size: "sm", isLink: false },
      { content: "Odd", size: "sm", isLink: false },
    ];
    const duosTableHeader = [
      { content: "players", size: "lg", isLink: false },
      { content: "team", size: "md", isLink: true, link: 'team' },
      { content: "league", size: "md", isLink: true, link: 'league' },
      { content: "games", size: "sm", isLink: false },
      { content: "One Goal", size: "sm", isLink: false },
      { content: "Two Goals", size: "sm", isLink: false },
      { content: "Three Goals", size: "sm", isLink: false },
      ];
    const triosTableHeader = [
      { content: "players", size: "lg", isLink: false },
      { content: "team", size: "md", isLink: true, link: 'team' },
      { content: "league", size: "md", isLink: true, link: 'league' },
      { content: "games", size: "sm", isLink: false },
      { content: "One Goal", size: "sm", isLink: false },
      { content: "Two Goals", size: "sm", isLink: false },
      { content: "Three Goals", size: "sm", isLink: false },
      ];


    useEffect(() => {
        if (players !== null) {
            let filteredPlayers = players.players;
            let filteredDuos = players.duos;
            let filteredTrios = players.trios;
            if (selectedLeague !== null) {
                filteredPlayers = filteredPlayers.filter(player => player.current_team.league && player.current_team.league.id === selectedLeague.id);
                filteredDuos = filteredDuos.filter(duo => duo.team.league && duo.team.league.id === selectedLeague.id);
                filteredTrios = filteredTrios.filter(trio => trio.team.league && trio.team.league.id === selectedLeague.id);
            }
            if (propType !== null && view === "players") {
                if (propType === "GOALS") {
                    filteredPlayers = filteredPlayers.filter(player => player.is_top_striker === true);
                } else if (propType === "ASSISTS") {
                    filteredPlayers = filteredPlayers.filter(player => player.is_top_passer === true);
                }
            }
       setplayersTableContent(
                filteredPlayers.map((player) => [
                    [player.name, player.id],
                    player.position,
                    player.winamax_id,
                    [player.current_team.name, player.current_team.id],
                    [player.current_team.league.name, player.current_team.league.id],
                    player.is_top_striker,
                    player.is_top_passer,
                    homeAway === "AWAY" ? player.domestic_games_started_away : homeAway === "HOME" ? player.domestic_games_started_home : player.domestic_games_started,
                    homeAway === "AWAY" ? player.domestic_away_games_with_goals : homeAway === "HOME" ? player.domestic_home_games_with_goals : player.domestic_games_with_goals,
                    homeAway === "AWAY" ? (1 / player.domestic_goals_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / player.domestic_goals_home_ratio).toFixed(2) : (1 / player.domestic_goals_ratio).toFixed(2),
                    homeAway === "AWAY" ? player.domestic_away_games_with_assists : homeAway === "HOME" ? player.domestic_home_games_with_assists : player.domestic_games_with_assists,
                    homeAway === "AWAY" ? (1 / player.domestic_assists_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / player.domestic_assists_home_ratio).toFixed(2) : (1 / player.domestic_assists_ratio).toFixed(2),
                    homeAway === "AWAY" ? player.domestic_away_games_with_decisive : homeAway === "HOME" ? player.domestic_home_games_with_decisive : player.domestic_games_with_decisive,
                    homeAway === "AWAY" ? (1 / player.domestic_decisive_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / player.domestic_decisive_home_ratio).toFixed(2) : (1 / player.domestic_decisive_ratio).toFixed(2),

                ])
            );
            setDuosTableContent(
                filteredDuos.map((duo) => [
                    `${duo.player1.name} - ${duo.player2.name}`,
                    [duo.team.name, duo.team.id],
                    [duo.team.league.name, duo.team.league.id],
                    homeAway === "AWAY" ? duo.games_played_away : homeAway === "HOME" ? duo.games_played_home : duo.games_played,
                    homeAway === "AWAY" ? (1 / duo.games_scored_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / duo.games_scored_home_ratio).toFixed(2) : (1 / duo.games_scored_ratio).toFixed(2),
                    homeAway === "AWAY" ? (1 / duo.games_scored_two_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / duo.games_scored_two_home_ratio).toFixed(2) : (1 / duo.games_scored_two_ratio).toFixed(2),
                    homeAway === "AWAY" ? (1 / duo.games_scored_three_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / duo.games_scored_three_home_ratio).toFixed(2) : (1 / duo.games_scored_three_ratio).toFixed(2),
                ])
            );
            setTriosTableContent(
                filteredTrios.map((trio) => [
                    `${trio.player1.name} - ${trio.player2.name} - ${trio.player3.name}`,
                    [trio.team.name, trio.team.id],
                    [trio.team.league.name, trio.team.league.id],
                    homeAway === "AWAY" ? trio.games_played_away : homeAway === "HOME" ? trio.games_played_home : trio.games_played,
                    homeAway === "AWAY" ? (1 / trio.games_scored_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / trio.games_scored_home_ratio).toFixed(2) : (1 / trio.games_scored_ratio).toFixed(2),
                    homeAway === "AWAY" ? (1 / trio.games_scored_two_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / trio.games_scored_two_home_ratio).toFixed(2) : (1 / trio.games_scored_two_ratio).toFixed(2),
                    homeAway === "AWAY" ? (1 / trio.games_scored_three_away_ratio).toFixed(2) : homeAway === "HOME" ? (1 / trio.games_scored_three_home_ratio).toFixed(2) : (1 / trio.games_scored_three_ratio).toFixed(2),
                ])
            );
        }
    }, [players, selectedLeague, homeAway, propType, view]);
  return (
    <>
    <div className="content">
    <div className="league-choice">
          {!playersLoading && players.leagues.map((league) => (
            <img
              key={league.id}
              src={league.logo_url}
              alt={league.name}
              onClick={() => { selectedLeague === league ? setSelectedLeague(null) : setSelectedLeague(league); }}
              className={selectedLeague === league ? 'active' : ''}
            />
          ))}
        </div>
    <div class="container">
        {playersLoading && <Loading />}
        {!playersLoading && !playersError && players && 
                    <>
                    <div className="buttons">
                      <div className={`button ${view === "players" ? 'active' : ''}`} onClick={() => { view !== "players" && setView("players") && setPropType(null) && setHomeAway(null); }}>PLAYERS</div>
                      <div className={`button ${view === "duos" ? 'active' : ''}`} onClick={() => { view !== "duos" && setView("duos") && setPropType(null) && setHomeAway(null); }}>DUOS</div>
                      <div className={`button ${view === "trios" ? 'active' : ''}`} onClick={() => { view !== "trios" && setView("trios") && setPropType(null) && setHomeAway(null); }}>TRIOS</div>
                    </div>
                    <div className="buttons">
                      <div className={`button ${homeAway === "HOME" ? 'active' : ''}`} onClick={() => { homeAway === "HOME" ? setHomeAway(null) : setHomeAway("HOME"); }}>HOME ONLY</div>
                  <div className={`button ${homeAway === "AWAY" ? 'active' : ''}`} onClick={() => { homeAway === "AWAY" ? setHomeAway(null) : setHomeAway("AWAY"); }}>AWAY ONLY</div>
                </div>
                <div className="buttons">
                    <div className={`button ${propType === "GOALS" ? 'active' : ''}`} onClick={() => { propType === "GOALS" ? setPropType(null) : setPropType("GOALS"); }}>GOALS</div>
                  <div className={`button ${propType === "ASSISTS" ? 'active' : ''}`} onClick={() => { propType === "ASSISTS" ? setPropType(null) : setPropType("ASSISTS"); }}>ASSISTS</div>
                </div>
                {view === "players" && <Table tableContent={playersTableContent} tableHeader={playersTableHeader} />}
                {view === "duos" && <Table tableContent={duosTableContent} tableHeader={duosTableHeader} />}
                {view === "trios" && <Table tableContent={triosTableContent} tableHeader={triosTableHeader} />}
                </>
                
                }
        {playersError && <>{playersError} - Please contact Florent</>}
  </div>
  </div>

</>
  )
}

export default Players