import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../css/main.css';
import Loading from './Loading';
import Table from './Table';


const Player = () => {
    const { id } = useParams();
    const [tab, setTab] = useState("games")
    const { result: player, isLoading: playerLoading} = useFetch(`/player/${id}`,[]);
    const [transfersTableContent, setTransfersTableContent] = useState(null);

    const transfersTableHeader = [
        { content: "Date", size: "md", isLink: false },
        { content: "Previous Team", size: "md", isLink: true, link: 'team' },
        { content: "New Team", size: "md", isLink: true, link: 'team' }
    ];

    useEffect(() => {
        if (player !== null) {
       setTransfersTableContent(
                player.transfers.map((transfer) => [
                    `${transfer.date.slice(5, 7)} - ${transfer.date.slice(0, 4)}`, [transfer.previous_team && transfer.previous_team.name, transfer.previous_team && transfer.previous_team.id], [transfer.new_team && transfer.new_team.name, transfer.new_team && transfer.new_team.id]])
            );
        }
    }, [player]);


  return (
   <>
            {playerLoading ? (
                <Loading />
            ) : player && (
                <>
                <div className="content">
                    <div className="header">
                        <div className="info">
                            <center>
                            <b>Team</b><br /><Link to={`/team/${player.current_team && player.current_team.id}`}>{player.current_team && player.current_team.name}</Link>
                            <br />
                            <br />
                        <b>Country</b><br />{player.current_team && player.current_team.league.country.name}
                        <br />
                        <br />
                        <b>League</b><br /><Link to={`/league/${player.current_team && player.current_team.league.id}`}>{player.current_team && player.current_team.league.name}</Link>
                        </center>
                        </div>
                    </div>
                    <div className="header">
                        <img className="header-logo" src={player.photo_url} alt={player.name} />
                        <div className="header-title">{player.name}</div>
                    </div>
                    <div className="header">
                        <div className="info">
                            <center>
                            <b>Position</b><br />{player.position}
                            <br />
                            <br />
                        <b>Age</b><br />{player.age}
                        <br />
                        <br />
                        <b>Nationality</b><br />{player.nationality && player.nationality.name}
                        </center>
                        </div>
                    </div>
                </div>
                <div className="container">
                <div className="tabs-container">
                    <div onClick={()=>setTab("games")} class={`tab ${tab === "games" ? "active" : ""} `}>Games</div>
                    <div onClick={()=>setTab("transfers")} class={`tab ${tab === "transfers" ? "active" : ""} `}>Transfers</div>
                    <div onClick={()=>setTab("stats")} class={`tab ${tab === "stats" ? "active" : ""} `}>Stats</div>
                </div>
                    {tab === "transfers" &&
                    <>
                    {player && !playerLoading && <Table tableHeader={transfersTableHeader} tableContent={transfersTableContent} />}
                    {playerLoading && <Loading />}
                    </>
                    }
                    </div>
                </>
                )
            }
    </>
  )
}

export default Player;